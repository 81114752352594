
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient, HttpHandler } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routes';
import { NgxBootstrapConfig } from './shared/ngx-bootstrap-config/ngx-bootstrap-config';

import { TokenInterceptor } from './shared/interceptors/token.interceptor';
import { AuthService } from './shared/services/auth.service';
//import { PeopleFinderComponent } from './shared/components/people-finder/people-finder.component';

import { environment } from '../environments/environment';
import { StorageService } from './shared/services/storage.service';

import { AlertService } from './shared/services/alert.service';
import { AlertComponent } from './shared/directives/alert-shim/alert-shim.component';
import { LoadingComponent } from './shared/components/loading-page/loading-page.component';
import { LoadingService } from './shared/services/loading.service';
import { HttpService } from './shared/services/http.service';
import { AuthProvider, LDAPProvider, AzureADProvider } from './shared/models/auth-provider.model';


import { AppService } from './app.service';
import { SharedModule } from './shared/shared.module';
import { GlobalErrorHandlerService } from './shared/services/global.error-handler.service';
import { Location, PathLocationStrategy, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { LoggerService } from './shared/services/logger.service';
import { ErrorsService } from './shared/services/errors.service';
import { CanDeactivateGuard } from './shared/components/can-deactivate/can-deactivate.guard';
import { AppPermissionGuard } from './app-permission.guard';
import { SelectModule } from 'ng2-select/ng2-select';


import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { NavigateService } from './shared/services/navigate.service';
import { AppAuthorizedGuard } from './app-authorized.guard.';

import { JwtModule } from '@auth0/angular-jwt';
import { DataConvertInterceptor } from './shared/interceptors/data-convert.Interceptor';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalService } from '@azure/msal-angular';
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from './shared/interceptors/msal.interceptor';
import { BrowserCacheLocation, Configuration, InteractionType, PublicClientApplication } from '@azure/msal-browser';

export const msalGuardConfig: MsalGuardConfiguration = {
  interactionType: InteractionType.Popup,
  authRequest: {
    scopes: ['user.read']
  },
  loginFailedRoute: '/login-failed'
};

export const msalInterceptorConfig: MsalInterceptorConfiguration = {
  interactionType: InteractionType.Popup,
  protectedResourceMap: new Map([
    ['https://graph.microsoft.com/v1.0/me', ['user.read']]
  ])
};
const msalConfig: Configuration = {
  auth: {
    clientId: environment.authConfig.clientId,
    authority: environment.authConfig.authority,
    redirectUri: environment.authConfig.redirectUri,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: true,
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);
export function initializeMsal(msalService: MsalService): () => Promise<void> {
  return () => msalService.instance.initialize().then(() => {
    console.log("MSAL initialized successfully.");
  }).catch((error) => {
    console.error("MSAL initialization failed:", error);
  });
}

@NgModule({
  declarations: [
    AppComponent,
    AlertComponent,
    LoadingComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    //SelectModule,
    ProgressbarModule.forRoot(),
    TabsModule.forRoot(),
    TypeaheadModule.forRoot(),
    JwtModule
  ],
  providers: [
    {
      provide: MSAL_INSTANCE,
      useValue: msalInstance
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useValue: msalGuardConfig
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useValue: msalInterceptorConfig
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeMsal,
      deps: [MsalService],
      multi: true
    },

    NgxBootstrapConfig,
    StorageService,
    NavigateService,
    { provide: AuthService, useClass: AzureADProvider, multi: false },
    Location,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    GlobalErrorHandlerService,
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    AlertService,
    AppService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: DataConvertInterceptor, multi: true },
    ErrorsService,
    LoadingService,
    LoggerService,
    HttpService,
    CanDeactivateGuard,
    AppPermissionGuard,
    AppAuthorizedGuard,
    MsalService,
    MsalGuard,
    MsalBroadcastService, 
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
