
import { HttpService } from "../services/http.service";
import { StorageService } from "../services/storage.service";
import { environment } from "../../../environments/environment";
import { Inject, Injectable } from "@angular/core";
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular'
import { AuthenticationResult, BrowserCacheLocation, InteractionRequiredAuthError, InteractionType, PopupRequest, PublicClientApplication, RedirectRequest } from "@azure/msal-browser";

@Injectable({
    providedIn: 'root'
})
export abstract class AuthProvider {
    abstract init();
    abstract login();
    abstract User: any;
    abstract IsAuthenticated: boolean;
    abstract AccessToken: string;
    abstract GraphToken();
    abstract searchByPeople();
    abstract clearCache(): void;
}

const msalConfig={
      auth: {
          clientId: environment.authConfig.clientId,
          authority: environment.authConfig.authority,
          redirectUri: environment.authConfig.redirectUri
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: true, // set to true for IE 11
        }
}
@Injectable({
    providedIn: 'root'
})
export class AzureADProvider implements AuthProvider {
    constructor(
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private httpService: HttpService,
        private storageService: StorageService,
        private msalService: MsalService) {

        // Logging = {
        //     level: 3,
        //     log: function (message) {
        //         // console.log(message);
        //     }
        // };
        //this.init();
        // this.msalService = new PublicClientApplication(msalConfig);
        // msalInstance.initialize();
    }

    init() {
        Object.assign(environment.authConfig, { isAngular: true });
        //environment.authConfig.
        var config = environment.authConfig;
        // config['localLoginUrl'] =this.getCurrentRoute();
    }
    refreshToken() {
    }
    login() {
        if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
          if (this.msalGuardConfig.authRequest) {
            this.msalService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
              .subscribe((response: AuthenticationResult) => {
                this.msalService.instance.setActiveAccount(response.account);
              });
          } else {
            this.msalService.loginPopup()
              .subscribe((response: AuthenticationResult) => {
                this.msalService.instance.setActiveAccount(response.account);
              });
          }
        } else {
          if (this.msalGuardConfig.authRequest) {
            this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
          } else {
            this.msalService.loginRedirect();
          }
        }
      }
    get User() {
        //debugger
        var user = this.msalService.instance.getAllAccounts()[0];
        if (user) {
            return user.idTokenClaims;
        } else {
            return null;
        }

    }
    GraphToken(){}
    get AccessToken(): string {
        this.getAccessToken().then(token => {
            localStorage.setItem("access.token",token);
            return token;
        }).catch(error => {
            console.error('Error acquiring token:', error);
        });
        return "";

    }
    get IsAuthenticated(): boolean { const accounts = this.msalService.instance.getAllAccounts(); return accounts.length > 0; }
    getAccountInfo() { const accounts = this.msalService.instance.getAllAccounts(); return accounts.length > 0 ? accounts[0] : null; }


    async getAccessToken(): Promise<string> {
        const accounts = this.msalService.instance.getAllAccounts();
        if (accounts.length > 0) {
            const request = {
                scopes: ['user.read'], // Replace with your required scopes
                account: accounts[0]
            };

            try {
                const response = await this.msalService.instance.acquireTokenSilent(request);
                return response.accessToken;
            } catch (error) {
                if (error instanceof InteractionRequiredAuthError) {
                    return this.acquireTokenInteractive(request);
                } else {
                    throw error;
                }
            }
        } else {
            throw new Error('No accounts found.');
        }
    }

    private async acquireTokenInteractive(request: any): Promise<string> {
        const response: AuthenticationResult = await this.msalService.instance.acquireTokenPopup(request);
        return response.accessToken;
    }

    clearCache(): void {
    }

    handleWindowCallback() {
        this.msalService.instance.handleRedirectPromise().then((result: AuthenticationResult | null) => {
            if (result) {
              console.log('Login handleWindowCallback:', result);
            }
          }).catch(error => {
            console.error('Error handling redirect:', error);
          });
      
    }
    searchByPeople() { }
}

@Injectable({
    providedIn: 'root'
})
export class LDAPProvider implements AuthProvider {

    currentUserKey: string = ''
    constructor(
        private httpService: HttpService) {
        this.currentUserKey = 'currentuser'
    }
    init() {
        this.login()
    }
    login(): any {
        this.httpService.get('/api/user')
            .subscribe(data => {
                // this.IsAuthenticated = true
                sessionStorage.setItem(this.currentUserKey, JSON.stringify(data))
                return JSON.stringify(data) //this.User
            }, err => {
                // this.IsAuthenticated = false
                sessionStorage.removeItem(this.currentUserKey);
                throw err;
            });
    }
    get User(): any {
        let currentUser = JSON.parse(sessionStorage.getItem(this.currentUserKey))
        if (currentUser) {  // this.IsAuthenticated
            return currentUser
        } else {
            return null;
        }
    }
    // get User(): any {
    //     let currentUser = JSON.parse(sessionStorage.getItem(this.currentUserKey))
    //     if (currentUser) {  // this.IsAuthenticated
    //         return currentUser
    //     } else {
    //         return this.login()
    //     }
    // }

    AccessToken: string;

    get IsAuthenticated(): boolean {
        let currentUser = JSON.parse(sessionStorage.getItem(this.currentUserKey))
        if (currentUser) {  // this.IsAuthenticated
            return true
        } else {
            return false
        }
    }

    GraphToken() { }

    searchByPeople() { }

    clearCache(): void {
        throw new Error("Method not implemented.");
    }
}