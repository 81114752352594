import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../environments/environment";
import { Observable } from "rxjs";
import { HttpService } from "./shared/services/http.service";
import { Router } from '@angular/router';
import { Menu, UserLogin } from "./app.model";

@Injectable()
export class AppService {

    public PermissionMenu: Menu = new Menu;
    public CurrectUser: any;

    constructor(
        private httpService: HttpService, private router: Router) {
    }

    getAttachmentConfigs(): Observable<any> {
        return this.httpService.get('/api/systemConfig/AttachmentConfigs')
    }

    AuthorizedApplication() {
        return this.httpService.get('/api/user/Authorized')
    }

    GetMenuPermission() {
        this.httpService.get('/api/Menu/Permission').subscribe(res => {
            localStorage.setItem('MenuPermission', JSON.stringify(res));
        })
    }
    Login(): Observable<UserLogin> {
        return this.httpService.get('/api/login');
    }
    FakeLogin(login): Observable<UserLogin> {
        return this.httpService.get(`/api/fake/${login}`);
    }
    DeploymentDate(): Observable<any>  {
        return this.httpService.get('/api/config/deploymentdate')
    }

}