import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { LoadingService } from '../services/loading.service';
import { map, catchError, finalize, tap, timeout } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public authService: AuthService,
    private loaderService: LoadingService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var accessToken = localStorage.getItem("access.token")??'';
    if (accessToken=='')
        return;
    var appToken = localStorage.getItem("app.token") ? localStorage.getItem("app.token") : '';
    //debugger;
    request = request.clone({
      withCredentials: true,
      reportProgress: true,
      setHeaders: {
        Authorization: `Bearer ${accessToken}`,
        'access-token':`${accessToken}`,
        'app-token': `${appToken}`
      }
    });
   


    if (request.method === 'POST' || request.method === 'PUT') {
      this.shiftDatesBody(request.body);
    }


    //console.log('intercept!!' , request);

    return next.handle(request)
      //.retry(3)
      .pipe(
        //tap(event => this.loaderService.showLoading()),
        map(event => this.updateEvent(event, request.url, request)),
        catchError(
          (error: any, caught: Observable<HttpEvent<any>>) => {
            this.loaderService.forceClosedLoading();
            throw error;
          }
        ),
        finalize(() => {
          if (this.validateUseLoading(request))
            this.loaderService.hideLoading();
        })
      );
  }
  private validateUseLoading(request:HttpRequest<any>) {
    if(request.url.indexOf('pipeline/FEMBA_Copy_SO_S1/status')!==-1){
      return false;
    }


    if (!request.headers.get('pagin-data') || (request.headers.get('pagin-data') && !JSON.parse(request.headers.get('pagin-data')).IsLazy)) {
      return true;
    }
  }


  private updateEvent(event: HttpEvent<any>, url: string, request) {
    switch (event.type) {
      case HttpEventType.Sent: {
        //console.log('request sent',HttpEventType.Sent,url);
        if (this.validateUseLoading(request))
          this.loaderService.showLoading();
        break;
      }
      case HttpEventType.UploadProgress: {
        //console.log('UploadProgress',HttpEventType.UploadProgress,event);
        break;
      }
      case HttpEventType.DownloadProgress: {
        //console.log('DownloadProgress',HttpEventType.DownloadProgress,event);
        break;
      }
      case HttpEventType.ResponseHeader: {
        //console.log('ResponseHeader',HttpEventType.ResponseHeader,event);
        break;
      }
      case HttpEventType.User: {
        //console.log('User',HttpEventType.User,event);

        break;
      }
      case HttpEventType.Response: {
        //console.log('response',HttpEventType.Response,url);

        //this.loaderService.hideLoading();
        //console.log(' HttpEventType.Response', HttpEventType.Response,event.type)
        return event;
      }
      default: {
        //console.log('default',event);
        break;
      }
    }
  }

  shiftDatesBody(body) {
    if (body === null || body === undefined) {
      return body;
    }

    if (typeof body !== 'object') {
      return body;
    }

    for (const key of Object.keys(body)) {
      const value = body[key];
      if (value instanceof Date) {
        body[key] = new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate(), 0, 0));
      } else if (typeof value === 'object') {
        this.shiftDatesBody(value);
      }
    }
  }

}

