export const environment = {
    production: true,
    authConfig: {
      tenant: 'chevron.onmicrosoft.com',
      clientId: '2c28f08f-faff-479d-b26e-cdd9d0c57479',
      authority: 'https://login.microsoftonline.com/fd799da1-bfc1-4234-a91c-72b3a1cb9e26',
      redirectUri: 'https://mba-test.azure.chevron.com/',
      graphApis: {
        users: 'https://graph.microsoft.com/v1.0/users',
        me: 'https://graph.microsoft.com/v1.0/me'
      },
      scope: {
        basePath: 'https://mba-api-qa.azure.chevron.com/',
        userImpersonation: 'https://mba-test.azure.chevron.com/user_impersonation',
      },
      endpoints: {
        graphApiUri: "https://graph.microsoft.com",
      },
      cacheLocation: 'sessionStorage',
    },
    webApiUrl: 'https://mba-api-test.azure.chevron.com/',
    cacheLocation: 'localStorage', 
    //redirectUri:window.location.origin+'/#/search-material',
    //expireOffsetSeconds:3550,
    navigateToLoginRequestUrl:false,
  };
  